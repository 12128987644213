import "core-js/modules/es.array.push.js";
import { adminStateColor } from '../../../utils/index.js';
export default {
  data() {
    return {
      roleVisible: false,
      roleForm: {},
      adminId: 0,
      adminList: [],
      roleList: [],
      chooseForm: {},
      defaultProps: {
        children: 'children',
        label: 'name',
        disabled: 'disabled'
      },
      currentPage: 1,
      pageSize: 10,
      state: [{
        label: '启用状态',
        value: 1
      }, {
        label: '禁用状态',
        value: 2
      }, {
        label: '离职状态',
        value: 3
      }]
    };
  },
  mounted() {
    this.getAdminList();
    this.getOrganizationList();
  },
  methods: {
    adminStateColor,
    async getOrganizationList() {
      this.$store.commit('system/setOrganizationLoading', true);
      try {
        await this.$store.dispatch('system/getOrganizationList');
      } catch (err) {
        this.$store.commit('system/setOrganizationLoading', false);
        return;
      }
    },
    async getAdminList() {
      let params = {
        current_page: this.currentPage,
        page_size: this.pageSize,
        name: this.chooseForm.name,
        dept_ids: !this.chooseForm.dept_ids ? [] : this.chooseForm.dept_ids.join(','),
        state: this.chooseForm.state
      };
      try {
        this.$store.commit('system/setAdminLoading', true);
        await this.$store.dispatch('system/getAdminList', {
          params
        });
        this.adminList = this.$store.state.system.adminList;
      } catch (err) {
        this.$store.commit('system/setAdminLoading', false);
        return;
      }
    },
    async getRoleList() {
      this.$store.commit('system/setSystemRoleLoading', true);
      try {
        await this.$store.dispatch('system/getSystemRoleList');
        this.roleList = this.$store.state.system.systemRoleList;
      } catch (err) {
        this.$store.commit('system/setSystemRoleLoading', false);
        return;
      }
    },
    async showRoleForm(id) {
      try {
        this.getRoleList();
        await this.$store.dispatch('system/getAdminRole', {
          params: {
            id
          }
        });
        this.roleForm = this.$store.state.system.adminRole;
        this.roleVisible = true;
        this.$refs.roleForm.resetFields();
      } catch (err) {
        return;
      }
    },
    async updateAdminRole(body) {
      try {
        await this.$store.dispatch('system/updateAdminRole', body);
        this.roleVisible = false;
        this.getAdminList();
      } catch (err) {
        return;
      }
    },
    closeRoleForm() {
      this.roleVisible = false;
    },
    submitRoleForm() {
      this.$refs.roleForm.validate(valid => {
        if (valid) {
          this.updateAdminRole(this.roleForm);
        }
      });
    },
    resetForm() {
      this.chooseForm = {};
      this.pageSize = 10;
      this.searchAdmin();
    },
    changeCurrentPage(val) {
      this.currentPage = val;
      this.getAdminList();
    },
    changePageSize(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getAdminList();
    },
    searchAdmin() {
      this.currentPage = 1;
      this.getAdminList();
    },
    getCheckedNodes(nodeData) {
      this.chooseForm.dept_ids = [];
      let checkedArr = this.$refs.tree.getCheckedNodes();
      for (let i in checkedArr) {
        this.chooseForm.dept_ids.push(checkedArr[i].value);
      }
      if (nodeData.id) {
        let node = this.$refs.tree.getNode(nodeData.id);
        this.expandCheckedNotExpandNodes(node);
      }
    },
    expandCheckedNotExpandNodes(node) {
      let {
        tree
      } = this.$refs;
      if (node.checked && !node.expanded && !node.isLeaf) {
        node.expand(() => {
          let {
            childNodes
          } = node;
          for (let i = 0; i < childNodes.length; i++) {
            let childNode = childNodes[i];
            tree.$emit('check-change', childNode.data, childNode.checked, childNode.indeterminate);
          }
        });
      }
    },
    clearDeptIds() {
      this.$refs.tree.setCheckedKeys([]);
      this.chooseForm.dept_ids = [];
      this.getAdminList();
    },
    removeTag(val) {
      this.$refs.tree.setChecked(val, false, true);
    }
  }
};